<template>
  <form class="mx-form">
    <div class="mx-form-header">
      <h4 class="mb-3">{{ $t('setting.settings.title') }}</h4>
    </div>
    <b-alert :show="dismissAlert">
      {{ $t('setting.settings.changed') }}
    </b-alert>
    <div class="mb-3">
      <div class="mx-modal-form__element">
        <label class="form-label">{{ $t('setting.settings.user.identification') }}</label>
        <input disabled type="text" class="form-control" v-model="user.identification">
      </div>
      <div class="mx-modal-form__element">
        <label class="form-label">{{ $t('setting.settings.user.email') }}</label>
        <input disabled type="text" class="form-control" v-model="user.email">
      </div>

      <div class="mx-modal-form__element">
        <label class="form-label">{{ $t('setting.settings.form.first_name') }}</label>
        <input type="text" class="form-control" v-model="form.first_name">
        <div v-if="errors.first_name" class="invalid-feedback">
          {{ $t(`setting.settings.errors.first_name`) }}
        </div>
      </div>

      <div class="mx-modal-form__element">
        <label class="form-label">{{ $t('setting.settings.form.last_name') }}</label>
        <input type="text" class="form-control" v-model="form.last_name">
        <div v-if="errors.last_name" class="invalid-feedback">
          {{ $t(`setting.settings.errors.last_name`) }}
        </div>
      </div>

      <div class="mx-modal-form__element">
        <label class="form-label">{{ $t('setting.settings.form.language') }}</label>
        <b-form-select v-model="form.language" :options="languages" class="form-control"></b-form-select>
      </div>

      <div v-if="errors.password_confirmation" class="invalid-feedback">
        {{ $t('setting.password.form.password_do_not_match') }}
      </div>
    </div>
    <div class="mx-form-buttons">
      <router-link :disabled="button_disabled" tag="button" to="/" class="btn btn-peepz-default">
        {{ $t('company.abort') }}
      </router-link>
      <button :disabled="button_disabled" @click="save" type="button" class="btn btn-peepz-success">
        {{ $t('company.save') }}
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      dismissAlert: false,
      button_disabled: false,
      user: {},
      languages: [],
      form: {
        first_name: '',
        last_name: '',
        language: 'de'
      },
      errors: []
    }
  },
  mounted() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.user = this.$store.getters.user
      this.form.first_name = this.$store.getters.user.first_name
      this.form.last_name = this.$store.getters.user.last_name
      this.form.language = this.$store.getters.user.language
      this.form.user_id = this.$store.getters.user.id
      this.getUserLanguages()
    },
    getUserLanguages() {
      // const languages = ['de', 'en', 'ru']
      const languages = ['de']

      let language = this.$store.getters.user.language
      this.languages = []
      this.languages.push( { value: language, text: this.$t(`language.${language}.${language}`) })

      for (let lang in languages) {
        if (languages[lang] !== language) {
          this.languages.push( { value: languages[lang], text: this.$t(`language.${language}.${languages[lang]}`) })
        }
      }
    },

    fieldLength: function (field) {
      if (this.form[field].length === 0) {
        this.errors[field] = this.$t(`setting.settings.errors.${field}`)
        return false
      }

      return true
    },

    save() {
      this.errors = {}
      let flag = true

      for (let field of ['first_name', 'last_name']) {
        flag = flag === false ? false : this.fieldLength(field)
      }

      if (flag) {
        flag = true
        this.button_disabled = true
        this.dismissAlert = false
        const user = this.$store.getters.user

        this.form.history_user_id = user.id
        this.form.history_user_full_name = user.first_name + ' ' + user.last_name

        this.$store.dispatch('userEdit', this.form).then(() => {
          this.errors = {}
          this.getUser()
          this.dismissAlert = true
          this.button_disabled = false
        }).catch(error => {
          this.button_disabled = false
        })
      }
    }
  }
}
</script>
