<template>
  <form class="mx-form">
    <div class="mx-form-header">
      <h4 class="mb-3">{{ $t('setting.password.title') }}</h4>
    </div>
    <b-alert :show="dismissAlert">
      {{ $t('setting.password.form.password_changed') }}
    </b-alert>
    <div class="mb-3">
      <div class="mx-modal-form__element">
        <label class="form-label">{{ $t('setting.password.form.label') }}</label>
        <input type="text" class="form-control" v-model="form.password">
      </div>
      <div class="mx-modal-form__element">
        <label class="form-label">{{ $t('setting.password.form.label_confirmation') }}</label>
        <input type="text" class="form-control" v-model="form.password_confirmation">
      </div>
      <div v-if="errors.password_confirmation" class="invalid-feedback">
        {{ $t('setting.password.form.password_do_not_match') }}
      </div>
    </div>
    <div class="mx-form-buttons">
      <router-link :disabled="button_disabled" tag="button" to="/" class="btn btn-peepz-default">
        {{ $t('company.abort') }}
      </router-link>
      <button :disabled="button_disabled" @click="save" type="button" class="btn btn-peepz-success">
        {{ $t('company.save') }}
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      dismissAlert: false,
      button_disabled: true,
      form: {
        password: '',
        password_confirmation: '',
        history_user_id: '',
        history_user_full_name: ''
      },
      errors: {
        password: 0,
        password_confirmation: null,
      }
    }
  },
  watch: {
    'form.password'() {
      if (this.form.password.replace(/\s/g, '').length > 0) {
        this.button_disabled = false
      } else {
        this.button_disabled = true
      }
    }
  },
  methods: {
    save() {
      this.errors = {}

      if (this.form.password !== this.form.password_confirmation) {
        this.errors.password_confirmation = 'пароли несофподают!'
        return
      }

      const user = this.$store.getters.user

      this.form.user_id = user.id
      this.form.history_user_id = user.id
      this.form.history_user_full_name = user.first_name + ' ' + user.last_name

      this.$store.dispatch('userEdit', this.form).then(() => {
        this.errors = {}
        this.form.password = ''
        this.form.password_confirmation = ''
        this.dismissAlert = true
      })
    }
  }
}
</script>
