<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <router-link to="/" class="btn btn-outline-secondary">
        <ph-caret-left :size="22" />
      </router-link>
    </div>

    <div class="row justify-content-center" style="min-height: calc(100% - 130px)">
      <div class="col-md-8 col-sm-12 mx-card">
        <div class="mx-form-header mb-4 mt-3">
          <h4 class="mb-3">{{ $t('setting.settings.title') }}</h4>
        </div>
        <b-tabs content-class="mt-3">
          <b-tab :title="$t('setting.settings.tab_title')" active>
            <Settings />
          </b-tab>
          <b-tab :title="$t('setting.password.tab_title')">
            <Password />
          </b-tab>
        </b-tabs>
      </div>
    </div>

  </CabinetPage>
</template>

<script>

import { PhBuildings, PhIdentificationCard, PhPlus, PhDotsThreeVertical, PhCaretLeft  } from "phosphor-vue";
import CabinetPage from "@/components/main/CabinetPage";
import Password from "@/components/settings/Password";
import Settings from "@/components/settings/Settings";

export default {
  components: {CabinetPage, Password, Settings, PhBuildings, PhIdentificationCard, PhPlus, PhDotsThreeVertical, PhCaretLeft},
  data() {
    return {
      preloader: true,
    }
  },
  mounted() {
    setTimeout(this.preload, this.options.preload_time);
  },
  methods: {
    preload() {
      this.preloader = false;
    }
  }
}
</script>
